import { Progress, Button, Anchor, Tag, Divider } from "antd";
import { useState } from "react";
import {
  delegate,
  execute,
  getActions,
  getDiscussionLinkByProposalId,
  getIpfsDocumentByProposalId,
  getProposalState,
  getTotalSupply,
  hasVoted,
  proposals,
  queue,
  voteWithReason,
} from "../open2web3";
import { useEffect } from "react";
import { useRecoilState , useRecoilValue } from "recoil";
import { balance, connected, totalSypply, Update } from "../atom";
import Web3 from "web3";
import { ipfsBaseLink } from "../open2web3";
import {FileTextTwoTone,  AliwangwangOutlined} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TotalSupplyComponent from "./TotalSupplyComponent";

const ProposalCard = (props) => {
  const [connectedAddress, setConnectedAddress] = useRecoilState(connected);
  const [prposalState, setProposalState] = useState(null);
  const [prposalStateColor, setPrposalStateColor] = useState(null);
  const [proposalDetails, setProposalDetails] = useState(null);
  const [voted, setVoted] = useState(null);
  const contractTotalSypply = useRecoilValue(totalSypply);
  const web3 = new Web3(Web3.givenProvider);
  const [balanceConnectedUser, setBalanceConnectedUser] =
    useRecoilState(balance);
 
  const [ipfsDoc, setIpfsDoc] = useState("");
  const [discussionLinkState, setDiscussionLinkState] = useState("");
  const [againstPercent , setAgainstPercent] = useState("")
  const [updateState , setUpdateState] = useRecoilState(Update);

  const navigate = useNavigate();




  useEffect(() => {
    // Update the document title using the browser API
    // console.log('nidhal', props.proposalId)
    getPropsalStateHandler();
    getProposalDetails();
    hasVotedCurrentProposal();
    getIpfsDocument();
    getDiscussionLink();

    
  }, [contractTotalSypply ,updateState]);


  useEffect(()=>{
    setAgainstPercent((
      (web3.utils.fromWei(
        proposalDetails != null ? proposalDetails.againstVotes : "0",
        "ether"
      ) /
        contractTotalSypply) *
      100
    ).toFixed(2))

  },[proposalDetails])

  const getIpfsDocument = async () => {
    const res = await getIpfsDocumentByProposalId(props.proposalId);
    if (res.ipfsDocument != "") {
      setIpfsDoc(ipfsBaseLink + res.ipfsDocument);
    }
 
  };

  const getDiscussionLink = async () => {
    const res = await getDiscussionLinkByProposalId(props.proposalId);
    if (res.discussionLink != "") {
      setDiscussionLinkState(res);
    }
  };


  const getProposalDetails = async () => {
    const res = await proposals(props.proposalId);
    if(props.proposalId=="45742810133481937871980713168867924176752374447007282352923552633898806076994"){
      // console.log("prop details ", res)
    }
    
    setProposalDetails(res);
    
  };
  const hasVotedCurrentProposal = async () => {
    const res = await hasVoted(props.proposalId, connectedAddress);

    setVoted(res);
  };

  const executeProposalHandler = async () => {
   
    const result = await execute(
      connectedAddress,
      props.proposalId,
      props.description
    );
    getPropsalStateHandler();


  };

  const queueProposalHandler = async () => {
  
    const result = await queue(
      connectedAddress,
      props.proposalId,
      props.description
    );
    getPropsalStateHandler();

   
  };

  const getPropsalStateHandler = async () => {
    const res = await getProposalState(props.proposalId);

    switch (parseInt(res)) {
      case 0:
        setProposalState("Pending");
        setPrposalStateColor("purple");
        break;
      case 1:
        setProposalState("Active");
        setPrposalStateColor("blue");
        break;
      case 2:
        setProposalState("Canceled");
        setPrposalStateColor("red");
        break;
      case 3:
        setProposalState("Failed");
        setPrposalStateColor("red");
        break;
      case 4:
        setProposalState("Passed");
        setPrposalStateColor("green");
        break;
      case 5:
        setProposalState("Queued");
        setPrposalStateColor("green");
        break;
      case 6:
        setProposalState("Expired");
        setPrposalStateColor("magenta");
        break;
      case 7:
        setProposalState("Executed");
        setPrposalStateColor("green");
        break;
    }
  };

  const voteHandler = async (voteWay ,event) => {
    // await delegate(connectedAddress);
    event.stopPropagation()
    await voteWithReason(connectedAddress, props.proposalId, voteWay, "");
    getPropsalStateHandler();
    getProposalDetails();
    hasVotedCurrentProposal();
  };

  const openDocumentHandler = () => {
    window.open(ipfsDoc, "_blank");
  };
  const openLink = () => {
    window.open(discussionLinkState, "_blank");
  };

  const goToDetailsPage = ()=>{
    navigate("/proposal/"+props.proposalId);
  }

  return (
    <div className="InfoToken">
      <TotalSupplyComponent/>
      <div className="cardc" onClick={()=>goToDetailsPage()} style={{cursor:"pointer"}}>
        {/* <Button  type="primary" >details</Button> */}
     

        {/* <Button type="primary" onClick={()=>queueProposalHandler()} >
 Queue proposal 
</Button> */}


        <div className="statust">
        {prposalState === "Active" &&
        voted === false &&
        balanceConnectedUser > 0 ? (
          <div className="alignprop">
            <Button type="primary" onClick={(e) => voteHandler(1,e)}>
              Yes
            </Button>
            <Button type="primary m-2" onClick={(e) => voteHandler(0,e)}>
              No
            </Button>{" "}
            <Button type="primary" onClick={(e) => voteHandler(2,e)}>
              Abstain
            </Button>
          </div>
        ) : (
          ""
        )}
          
         <div className="deuP">
          <span>
            <Tag color={prposalStateColor}>{prposalState}</Tag>
          </span>
          <span className="moreI"><Tag>More details</Tag></span>
        </div>

        </div>
        <div className="stitle">{props.description}</div>
      <div className="AlignProgress">
        <div className="alignSC">
        <small>Yes</small>
          {" "}
          <Progress
            status="active"
            size="small"
            percent={(
              (web3.utils.fromWei(
                proposalDetails != null ? proposalDetails.forVotes : "0",
                "ether"
              ) /
                contractTotalSypply) *
              100
            ).toFixed(2)}
          />
        </div>
        {/* <div>{proposalDetails.id}</div> */}
      
        {/* <div> <Progress status="exception" size='small' percent={70 } /></div> */}
        <div  className="alignSC">
        <small>No</small>
          {" "}
          <Progress
            strokeColor={"red"}
            size="small"
            percent={againstPercent}
            status={againstPercent==100 ? "exception" : undefined }
          />
        </div>

        <div  className="alignSC">
           <small>Abstain</small>
          {" "}
          <Progress
            percent={(
              (web3.utils.fromWei(
                proposalDetails != null ? proposalDetails.abstainVotes : "0",
                "ether"
              ) /
                contractTotalSypply) *
              100
            ).toFixed(2)}
          />
        </div>
        </div>
      </div>
      <div className="footerMo">
        {prposalState === "Queued" ? (
          <Button
            type="primary"
            shape="round"
            className="mb-2"
            onClick={() => executeProposalHandler()}
          >
            Execute proposal
          </Button>
        ) : (
          ""
        )}
        {prposalState === "Passed" ? (
          <Button
            type="primary"
            shape="round"
            className="mb-2"
            onClick={() => queueProposalHandler()}
          >
            Queue proposal
          </Button>
        ) : (
          ""
        )}
        {/* {ipfsDoc  ? <div> <a href={ipfsDoc} download target='_blank'> Download</a></div> : ""} */}
        {ipfsDoc ? (
          <Button
            type="primary"
            shape="round"
            className="mb-2"
            icon={<FileTextTwoTone />}
            size={"Default"}
            onClick={openDocumentHandler}
          >
            Docs
          </Button>
        ) : (
          ""
        )}
        {discussionLinkState ? (
          <Button
            type="primary"
            shape="round"
            size={"Default"}
            onClick={openLink}
            className="mb-2"
          >
          Discussion <img src="/iconLink.svg" />
          </Button>
        ) : (
          ""
        )}
      </div>

      {/* <div> <TimePicker defaultValue={moment('13:30:56', 'HH:mm:ss')} /></div> */}
    </div>
  );
};

export default ProposalCard;
