import Web3 from "web3";
import { TokenErc20abi } from './contracts/TokenErc20abi';
import { GovernanceContractAbi } from './contracts/GovernanceContactAbi';
import { useRecoilState } from 'recoil';
/////////////DEMO O2B////////////////////////////////////////////
const chainId='0xa869';
export const blockExplorer = 'https://testnet.snowtrace.io';
 
const graphAPIToken ='https://api.thegraph.com/subgraphs/name/g-nidhal/erc20-token';
const graphAPIGov ='https://api.thegraph.com/subgraphs/name/g-nidhal/governance-contract';
export const graphTreasury ='https://api.thegraph.com/subgraphs/name/g-nidhal/treasury';


const TokenAddress = "0x3d7F37d0208f52E81d5Dd870BF2Fb87210d39Ec1";
const GovernanceContractAddress = "0xe1CcDc6BE26AD7fc1Cf8997f39A60b9a2A1BD826";
export const boxContractAddress = "0xCDEAB73c8Ce6224bB9c7d7a960bF684AFE992D2C";
export const timelockAddress = "0x62bD7A23bbd59dE30070945687460689Aa447ffa";
export const TokenAddressTreasury ="0x940e445168016D9aC463514A32Cf738a2a12851E";

export const pinataApiKey = '87849a92b2bae834095d';
export const pinataApiSecret ='28d6f3bdeecb254e57dbe65f03d58a431023e80287850af441013c4a9a395301';
export const ipfsBaseLink = 'https://gateway.pinata.cloud/ipfs/'

export const facebookLink='https://www.facebook.com/';
export const instagramLink='https://www.instagram.com/';
export const meduimLink='https://medium.com/';
export const telegramLink='https://telegram.org/';
export const discordLink='https://discord.com/';

//////////////////////////FIN///////////////////////////////////////

/////////////DEMO Client////////////////////////////////////////////
// const chainId='0xa869';
// export const blockExplorer = 'https://testnet.snowtrace.io';
 
// const graphAPIToken ='https://api.thegraph.com/subgraphs/name/g-nidhal/erc20-token-demo';
// const graphAPIGov ='https://api.thegraph.com/subgraphs/name/g-nidhal/governance-contract-demo';
// export const graphTreasury ='https://api.thegraph.com/subgraphs/name/g-nidhal/treasury-demo';


// const TokenAddress = "0xDd4601db8454dAB538678756c459168B108f142F";
// const GovernanceContractAddress = "0x51E2F2144521F11767EE9B6f66519909A758ecBB";
// export const boxContractAddress = "0xE1fEAf6Cd694d05DAD47AF843772aA6054d5bDA4";
// export const timelockAddress = "0x2bB6EFD82A94eD0205F678492634eb7BaeBB2739";
// export const TokenAddressTreasury ="0x2959d558C2490CE705AcB260238050B6EfC9B18D";

// export const pinataApiKey = '87849a92b2bae834095d';
// export const pinataApiSecret ='28d6f3bdeecb254e57dbe65f03d58a431023e80287850af441013c4a9a395301';
// export const ipfsBaseLink = 'https://gateway.pinata.cloud/ipfs/'

// export const facebookLink='https://www.facebook.com/';
// export const instagramLink='https://www.instagram.com/';
// export const meduimLink='https://medium.com/';
// export const telegramLink='https://telegram.org/';
// export const discordLink='https://discord.com/';

//////////////////////////FIN///////////////////////////////////////

 
let provider="" ; 
// console.log(1)
 try{

   provider = window.web3.currentProvider;
 }catch(error){
  
 }
 
// console.log(3)
const Web3Client = new Web3(provider);

const contract = new Web3Client.eth.Contract(TokenErc20abi, TokenAddress);

const contractTreasury = new Web3Client.eth.Contract(TokenErc20abi, TokenAddressTreasury);

const GovernanceContract = new  Web3Client.eth.Contract(GovernanceContractAbi,GovernanceContractAddress);

// The minimum ABI required to get the ERC20 Token balance
// const minABI = [
//     // balanceOf
//     {
//       constant: true,
//       inputs: [{ name: "_owner", type: "address" }],
//       name: "balanceOf",
//       outputs: [{ name: "balance", type: "uint256" }],
//       type: "function",
//     },
// ];



async function getBalance(walletAddress) {
  const result = await contract.methods.balanceOf(walletAddress).call(); // 29803630997051883414242659
  
  const format = Web3Client.utils.fromWei(result); // 29803630.997051883414242659

  // console.log(format);
  return format;
}

export async function getVotes(walletAddress) {
  const result = await  contract.methods.getVotes(walletAddress).call();
  const format = Web3Client.utils.fromWei(result);

  // console.log('getvotes :: ',result);
  return format;
}


 

async function getTotalSupply(){
    const  result = await contract.methods.totalSupply().call();
    const format = Web3Client.utils.fromWei(result);
    return format;
}
async function getTokenNameAndSymbol(){
    const  name = await contract.methods.name().call();
    const  symbol = await contract.methods.symbol().call();
    // const format = Web3Client.utils.fromWei(result);
    return name + " ( "+symbol+" )";
}
export async function getTokenSymbol(){
 
  const  symbol = await contract.methods.symbol().call();
  // const format = Web3Client.utils.fromWei(result);
  return   symbol ;
}



export async function getBalanceTreasury(walletAddress) {
  const result = await contractTreasury.methods.balanceOf(walletAddress).call(); // 29803630997051883414242659
  
  const format = Web3Client.utils.fromWei(result); // 29803630.997051883414242659

  // console.log(format);
  return format;
}


 

export async function getTotalSupplyTreasury(){
    const  result = await contractTreasury.methods.totalSupply().call();
    const format = Web3Client.utils.fromWei(result);
    return format;
}
export async function getTokenNameAndSymbolTreasury(){
    const  name = await contractTreasury.methods.name().call();
    const  symbol = await contractTreasury.methods.symbol().call();
    // const format = Web3Client.utils.fromWei(result);
    return name + " ( "+symbol+" )";
}


export async function getTokenSymbolTreasury(){
  
  const  symbol = await contractTreasury.methods.symbol().call();
  // const format = Web3Client.utils.fromWei(result);
  return   symbol ;
}

async function createPrposal(description ,account ,ipfsDocument="",discussionLink=""){
 
  const amount = await Web3Client.utils.toWei("1000",'ether');
  // console.log("amount to withdraw ",amount);
 

  const encodedFunc =await  Web3Client.eth.abi.encodeFunctionCall({
    "inputs": [
      {
        "internalType": "uint256",
        "name": "newValue",
        "type": "uint256"
      }
    ],
    "name": "store",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },[12]);
  
  //   const encodedFunc =await  Web3Client.eth.abi.encodeFunctionCall({
  //     "inputs": [
  //       {
  //         "internalType": "contract ERC20",
  //         "name": "token",
  //         "type": "address"
  //       },
  //       {
  //         "internalType": "address",
  //         "name": "to",
  //         "type": "address"
  //       },
  //       {
  //         "internalType": "uint256",
  //         "name": "amount",
  //         "type": "uint256"
  //       }
  //     ],
  //     "name": "transferErc20",
  //     "outputs": [],
  //     "stateMutability": "nonpayable",
  //     "type": "function"
  // },[TokenAddressTreasury,account ,amount]);
  

  // console.log('accc ',account);

  // const tx = await GovernanceContract.propose();
  let tx;
  tx = await GovernanceContract.methods.propose([boxContractAddress],[0],[encodedFunc],description,ipfsDocument,discussionLink).send({from : account});
  // if(ipfsDocument!=""){
    
  //    tx = await GovernanceContract.methods.proposeWithDocument([boxContractAddress],[0],[encodedFunc],description,ipfsDocument).send({from : account});
  // }else{

  //   tx = await GovernanceContract.methods.propose([boxContractAddress],[0],[encodedFunc],description).send({from : account});
  // }
  return tx;
}


export async function createWithdrawPrposal(address,amountToSend , description ,account ,ipfsDocument="",discussionLink=""){
 
  const amount = await Web3Client.utils.toWei(amountToSend,'ether');
  // console.log("amount to withdraw ",amount);
 

  // const encodedFunc =await  Web3Client.eth.abi.encodeFunctionCall({
  //   "inputs": [
  //     {
  //       "internalType": "uint256",
  //       "name": "newValue",
  //       "type": "uint256"
  //     }
  //   ],
  //   "name": "store",
  //   "outputs": [],
  //   "stateMutability": "nonpayable",
  //   "type": "function"
  // },[12]);
  
    const encodedFunc =await  Web3Client.eth.abi.encodeFunctionCall({
      "inputs": [
        {
          "internalType": "contract ERC20",
          "name": "token",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "transferErc20",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
  },[TokenAddressTreasury,address ,amount]);
  

  // console.log('accc ',account);

  // const tx = await GovernanceContract.propose();

  let tx;
  tx = await GovernanceContract.methods.propose([boxContractAddress],[0],[encodedFunc],description,ipfsDocument,discussionLink).send({from : account});
  // if(ipfsDocument!=""){

  //    tx = await GovernanceContract.methods.proposeWithDocument([boxContractAddress],[0],[encodedFunc],description,ipfsDocument).send({from : account});
  // }else{

  //    tx = await GovernanceContract.methods.propose([boxContractAddress],[0],[encodedFunc],description).send({from : account});
  // }
  return tx;
}

async function voteWithReason(account,proposalId , voteWay , reason){
  const tx = await GovernanceContract.methods.castVoteWithReason(proposalId,voteWay,reason).send({from : account});

  return tx;

}

async function getProposalState(propsalId){

  const res = await GovernanceContract.methods.state(propsalId).call();
  return res ;
}

 async function proposals(proposalId){

  const res = await GovernanceContract.methods.proposals(proposalId).call();
  return res;
}

export async function hasVoted(proposalId,account){
  const res = await GovernanceContract.methods.hasVoted(proposalId , account).call();
  return res ; 
}

export async function delegate(account){
  const res = await contract.methods.delegate(account).send({from : account});
  return res ; 
}

export async function getActions(proposalId){

  const res = await GovernanceContract.methods.getActions(proposalId).call();
  return res;

  
}

export async function getIpfsDocumentByProposalId(proposalId){

  const res = await GovernanceContract.methods.getIpfsDocument(proposalId).call();
  // console.log('ipfs document from smart contract : ',res);
  return res;

}

export async function getDiscussionLinkByProposalId(proposalId){

  const res = await GovernanceContract.methods.getDiscussionLink(proposalId).call();
  // console.log('getDiscussionLink from smart contract : ',res);
  return res;

}

export async function queue(account , proposalId,description){

 const  result = await getActions(proposalId) ; 
 const DescriptionHash = await Web3Client.utils.keccak256(description);
// console.log(result);
  const res = await GovernanceContract.methods.queue([boxContractAddress] , result.values , result.calldatas , DescriptionHash).send({from : account});

  return res;

}

export async function execute(account , proposalId,description){

  const  result = await getActions(proposalId) ; 
  const DescriptionHash = await Web3Client.utils.keccak256(description);
//  console.log(result);
   const res = await GovernanceContract.methods.execute([boxContractAddress] , result.values , result.calldatas , DescriptionHash).send({from : account});
 
   return res;
 
 }










 const networks = {
  avalanche: {
    chainId: `0x${Number(43114).toString(16)}`,
    chainName: 'Avalanche',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io/'],
  },
  Fuji: {
    chainId: `0x${Number(43113).toString(16)}`,
    chainName: 'Fuji Avalanche',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://testnet.snowtrace.io/'],
  },
  
}


export {TokenAddress , provider ,getBalance,getTotalSupply,getTokenNameAndSymbol,createPrposal,graphAPIToken,graphAPIGov ,getProposalState,voteWithReason,chainId,networks,proposals};