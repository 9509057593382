import { Button, Select , DatePicker, Space, Row, Col, TimePicker, Progress ,Modal, Form, Input, Spin, message, Tag  } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'antd/dist/antd.css';
import { createPrposal, createWithdrawPrposal, getProposalState, getTokenSymbolTreasury, graphAPIGov } from '../open2web3';
import { useRecoilState } from 'recoil';
import { connected, SymbolTreasuryToken, Update } from '../atom';
import ProposalCard from './ProposalCard';
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from "@apollo/client"; 
import { Pagination } from 'antd';
import { pinFileToIPFS } from './../ipfs';
import { LoadingOutlined } from '@ant-design/icons';



const Vote =  ()=> {

    const [connectedAddress , setConnectedAddress] = useRecoilState(connected);
    const { RangePicker } = DatePicker;
    const [symbolTreasury,setSymbolTreasury] = useRecoilState(SymbolTreasuryToken);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [isModal2Visible, setIsModal2Visible] = useState(false);
    const [selectedFile , setSelectedFile] = useState(null);


    const [proposals , setProposals] = useState([]);
    const [newproposal , setNewproposal] = useState([]);
    const [count, setCount] = useState();
    const [page, setPage] = useState(localStorage.getItem('page') ?Number(localStorage.getItem('page')) : 1);
    const [updateState , setUpdateState] = useRecoilState(Update);
    const [proposalCreationPending ,setProposalCreationPending ] = useState(false);
    const antIcon = <LoadingOutlined style={{ fontSize: 16 ,marginLeft: 5 }} spin />;


    let proposal = [];
    const perPage = 8;
    const client = new ApolloClient({
      uri: graphAPIGov,
      cache: new InMemoryCache()
    });


 

    useEffect(()=>{
        getProposalCount();
       
        getProposalsPage(page);
        getSymboleTreasuryToken();
       
      
  
    },[]);

    useEffect(()=>{
      console.log('up changed')
      getProposalCount();
      getProposalsPage(page);
      getSymboleTreasuryToken();
       

    },[updateState])

  //   useEffect(()=>{

  //     //getProposalsPage(1);
  //   //  getProposalState('12486974854505173721607186210014391845989616434778507598096971697055754601160').then(res=>{console.log('state ' ,res)});
  //     console.log('update changed ', update);
  // },[update]);

  const getSymboleTreasuryToken =async ()=>{
    const res = await getTokenSymbolTreasury();
    setSymbolTreasury(res);
  }

    const showModal = () => {
      setIsModalVisible(true);
    };

    const showModal2 = () => {
      setIsModal2Visible(true);
    };
  
    const handleOk = async () => {
    
      setIsModalVisible(false);
    };
    const handleOk2 = async () => {
    
      setIsModal2Visible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
    const handleCancel2 = () => {
      setIsModal2Visible(false);
    };

    const proposalCreationSuccess = () => {
      message.success('Proposal created');
    };

    const proposalCreationError = () => {
      message.error('Proposal creation failed');
    };

    const handlePropose = async ()=>{
      createPrposal("new proposal ",connectedAddress)
    }
    const onFinish =async (values) => {
      // console.log('Success:', values);
    //  setIsModalVisible(false);
     setProposalCreationPending(true)
     let hashIpfsDocument= "";
     if(selectedFile!=null){

       const  IpsfDocument =  await onFileUpload();
      //  console.log("uploadeded ipppfs docc : ",IpsfDocument.data.IpfsHash);
       hashIpfsDocument=IpsfDocument.data.IpfsHash;
     }
     try{

       const tx = await  createPrposal(values.description , connectedAddress , hashIpfsDocument,values.link);
       const proposalId = tx.events.ProposalCreated.returnValues.proposalId;
       fetchNewProposal(proposalId);
       setProposalCreationPending(false) 
       setIsModalVisible(false);
       proposalCreationSuccess()
     }catch{
      setProposalCreationPending(false) 
      setIsModalVisible(false);
      proposalCreationError()
     }
    };


    const onFinish2 =async (values) => {
      // console.log('Success:', values);
    //  setIsModal2Visible(false);
     setProposalCreationPending(true)
     let hashIpfsDocument= "";
     if(selectedFile!=null){

       const  IpsfDocument =  await onFileUpload();
      //  console.log("uploadeded ipppfs docc : ",IpsfDocument.data.IpfsHash,values.link);
       hashIpfsDocument=IpsfDocument.data.IpfsHash;
     }
    
     let description = "Transfer Proposal of "+values.amount +" "+symbolTreasury+" : "+values.description;
    //  console.log(description);
    try{
      const tx = await  createWithdrawPrposal(values.address,values.amount,description , connectedAddress ,hashIpfsDocument,values.link);
      const proposalId = tx.events.ProposalCreated.returnValues.proposalId;
      fetchNewProposal(proposalId); 
      setProposalCreationPending(false)
      setIsModal2Visible(false);
      proposalCreationSuccess()

    }catch{
      setProposalCreationPending(false)
      setIsModal2Visible(false);
      proposalCreationError()
    }
   
    };


    const getAllProposals = async()=>{

      await fetch(graphAPIGov,{
        method: 'POST',
        body: JSON.stringify({
          query: `{
            prposals(first: ${perPage} orderBy : startBlock orderDirection:desc) {
              id
              proposalId
              prposer
              startBlock
              description
            }
          }`
        })
    }).then( (response) => response.json())
    .then( json =>setProposals(json?.data?.prposals)  );
    // console.log(proposals);
    // setProposals(json?.data?.prposals)
    }

    const getProposalsPage = async(page) => {
        client
        .query({
          query: gql`{
              prposals(first: ${perPage}, skip: ${perPage * (page -1) } orderBy : startBlock orderDirection:desc){
                id
                proposalId
                prposer
                startBlock
                description
              }
            }`
        })
        .then(result => setProposals(result.data.prposals));
    }

    const getProposalByid = async(proposalId)=>{
      const response = await fetch(graphAPIGov,{
        method: 'POST',
        body: JSON.stringify({
          query :`{
            prposals(where:{proposalId : "${proposalId}"}) {
              id
              proposalId
              prposer
              startBlock
              description
            }
          }`
        })
      });
      const json = await response.json();
      setNewproposal(json?.data?.prposals ?? []);
      proposal = json?.data?.prposals ?? []
      // console.log("AAAA", newproposal, proposal)
      
    }

    const getProposalCount = ()=>{
      client
      .query({
        query: gql`{  
          prposalsInfos {           
             proposalsCount
           }                          
         }`
      }).then(result => setCount(result.data.prposalsInfos[0].proposalsCount));
    }

    const onPageChange = (page, pageSize) =>{
        console.log('page', "AAAAAAAAAAAAAAAAAAAA")
        localStorage.setItem('page', page);
        setPage(page);
        getProposalsPage(page);
    }

    const fetchNewProposal = async(proposalId)=>{
      const proposalNew = await getProposalByid(proposalId);
      setNewproposal(proposalNew);
      console.log("CCCCC",newproposal);
      if(!proposal.length){
        setTimeout(() => fetchNewProposal(proposalId), 5000)
      }else{
        setNewproposal([]);
        getProposalsPage(1);
      }
    }


   const  onFileChange = event => { 
      // Update the state 
       setSelectedFile(event.target.files[0]);
       console.log('Selected file ',event.target.files[0])  ; 
    }; 


    const onFileUpload =async()=>{
      // debugger;
      const formData = new FormData(); 
      formData.append( 
        "file", 
        selectedFile, 
        selectedFile.name 
      ); 
      formData.append('fileName',selectedFile.name);

     return await pinFileToIPFS(formData);


    }
    
      
      const listproposal = proposals.map((proposal) =>
      <Col xs={24} md={24} lg={24}>
      {/* <ProposalCard info=proposal /> */}
      <ProposalCard key={proposal.id} description={proposal.description} proposalId = {proposal.proposalId}  />
      </Col>);
     

    return ( 
        <>
    
         
           <Row gutter={20} className="mb-5">
             <Col  xs={24} md={4} lg={4}>
               <div style={{marginLeft:15,marginRight:15}}>
             <div><div className="alignV mb-4 mt-4"><Button type="primary" size={20} onClick={showModal}>New proposal</Button>
      
            <Button type="primary" className="brRM" size={20} onClick={showModal2}>New withdraw proposal</Button></div>
            <Modal title="Create proposal" visible={isModalVisible} footer={false} onOk={handleOk} onCancel={handleCancel} destroyOnClose={true}>
            <Form name="newProposalForm" onFinish={onFinish} >
    
    <Form.Item label="Description" name="description"  rules={[
        {
          required: true,
          message :'Proposal description is required!'
        },
      ]} >
      <Input  />
    </Form.Item>

    <Form.Item label="Document" name="document">
      <input  type="file" onChange={onFileChange}  />
    </Form.Item>

    <Form.Item label="Discussion link" name="link"  rules={[
        {
          required: false,
          // message :'Proposal description is required!'
        },
      ]} >
        <Input/>
      </Form.Item>
 
    <Form.Item>
      <div  className="AlignBP">
      {proposalCreationPending ==false && <Button type="primary" htmlType='submit'>Proposal</Button>}
      {proposalCreationPending ==true && <Button type="primary"  disabled={true}>Pending <Spin indicator={antIcon}/></Button>}
      </div>
    </Form.Item>
  </Form>
        
      </Modal>

           <Modal title="Create proposal" visible={isModal2Visible} footer={false} onOk={handleOk2} onCancel={handleCancel2} destroyOnClose={true}>
            <Form name="newProposalForm" onFinish={onFinish2}     >
    
    <Form.Item label="Description" name="description" rules={[
        {
          required: true,
          message :'Proposal description is required!'
        },
      ]} >
      <Input />
    </Form.Item>
    <Form.Item label="Address" name="address"  rules={[
        {
          required: true,
          message :'Destination address is required!'
        },
      ]} >
      <Input  />
    </Form.Item>

    <Form.Item label="Amount" name="amount"  rules={[
        {
          required: true,
          message: 'Amount is required!'
        },
        {          
          message :'Amount is not valid integer!',
          pattern: /^[0-9]+$/,
        },
      ]} >
      <Input  />
    </Form.Item>

    <Form.Item label="Document" name="document">
      <input  type="file" onChange={onFileChange}  />
    </Form.Item>
    <Form.Item label="Discussion link" name="link"  rules={[
        {
          required: false,
          // message :'Proposal description is required!'
        },
      ]} >
        <Input/>
      </Form.Item>
 
    <Form.Item>
      <div  className="AlignBP">
    {proposalCreationPending ==false &&<Button type="primary" htmlType='submit'>Proposal</Button>}
    {proposalCreationPending ==true &&<Button type="primary"  disabled={true}>Pending <Spin indicator={antIcon}/></Button>}
    </div>
    </Form.Item>
  </Form>
        
      </Modal>
            </div>
            {/* 
              <h5>Filtre</h5>
          
            <div className="alignBou mt-4">
            <button className="hB">Tous</button>
            <button  className="hB2">Active</button>
            <button  className="hv">Executed</button>
            <button  className="hR">Failed</button>
            </div>
            
            */}
          
            </div>
             </Col>
    
           <Col xs={24} md={20} lg={20}>
           <div className="addToken">
            <div  style={{marginLeft:15,marginRight:15}}><h1>List of proposals</h1></div>
            
         

        </div>
             
             {listproposal}
           <Pagination current={page} pageSize={perPage} total={count} onChange={onPageChange} style={{textAlign:'center'}} className="mt-5"/>

           
           </Col>
           
           </Row>
           </>

          
        
        
        
        
        
        
        );
}

export default Vote;