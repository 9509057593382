
import React , {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
const ProtectedRoute = ({ user, children }) => {
    const navigate = useNavigate();
    const [authenticated , setAuthenticated] = useState("");
    const [count, setCount] = useState(5);
// debugger;
//     useEffect(() => {
//         if(authenticated == false){
//             setAuthenticated("");
//            return navigate('/home'); 
//         } 
//       },[]); 
    // debugger;

    useEffect(() => {
      const interval = setInterval(() => {
        setCount((currentCount) => --currentCount);
      }, 100);
      // redirect once count is equal to 0
        user === "" && count===0 && navigate("/home");
        // cleanup
        return () => clearInterval(interval);
      }, [count, navigate]);


    if (user!="") {
        return children;
    } 
    // setAuthenticated(true);  
  };

  export default ProtectedRoute;