import React , {useEffect} from "react";
import { getTotalSupply } from "../open2web3";
import { useRecoilState } from 'recoil';
import { totalSypply } from './../atom';

function TotalSupplyComponent() {

    const [contractTotalSypply, setContractTotalSypply] =useRecoilState(totalSypply);

    const getTotalSupplyHandler = async ()=>{
        try{
    
          const supply = await getTotalSupply()
          setContractTotalSypply(supply);
           
        }catch(err){
            console.log(err);
        }
    }

    useEffect(()=>{
        getTotalSupplyHandler();
      })


    return ( <></> );
}

export default TotalSupplyComponent;